<template>
  <!-- 预约挂号详情页 -->
  <div class="detail">
    <div class="detail1">
      <div class="detail2">
        <div>条形码</div>
        <canvas id="canvas"></canvas>
      </div>
    </div>
    <div class="detail4">
      <div class="detail5">
        <div class="detail6">
          <span>就诊人：</span>
          <span>{{ order.Name }}</span>
        </div>
        <div class="detail6">
          <span>就诊卡号</span>
          <span>{{ order.PatientId }}</span>
        </div>
        <div class="detail6">
          <span>就诊序号：</span>
          <span>{{ order.SeeNo }}</span>
        </div>
        <div class="detail6">
          <span>状态：</span>
          <span style="color: #45e4ae" v-if="order.Flag == 1">{{
            status[order.Flag]
          }}</span>
          <span v-else>{{ status[order.Flag] }}</span>
        </div>
      </div>
      <div class="detail7">
        <div class="detail8">
          <span>科室：</span>
          <span>{{ order.DeptName }}</span>
        </div>
        <div class="detail9">
          <span>就诊时间：</span>
          <span style="color: #ff3366">{{
            order.ClinicDate + "  " + order.TimeDesc + "  " + order.CheckTime
          }}</span>
        </div>
        <div class="detail8">
          <span>预约时间：</span>
          <span>{{ order.ResDate }}</span>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <!-- <template #title>
        <h3>提示</h3> 
      </template> -->
      <template #default>
        <div class="a1">
          <div class="a2">
            <p class="a21">
              <img src="@/assets/img/ucenter/lingdang2.png" alt="" />
            </p>
          </div>
          <h3 class="dialog-message">提示</h3>
          <p class="dialog-message1">确定要取消预约吗？</p>
          <van-button class="cancle-btn" @click="showDialog = false"
            >取消</van-button
          >
          <van-button type="primary" class="confirm-btn" @click="submit"
            >确认</van-button
          >
        </div>
        <div class="a31">
          <img
            src="../../assets/img/ucenter/20.png"
            alt=""
            @click="showDialog = false"
          />
        </div>
      </template>
    </van-dialog>
    <!-- <van-button
      class="detail20"
      plain
      @click="submitData"
      v-if="order.Flag == 1"
      >取消预约</van-button
    > -->
  </div>
</template>
<script>
import JsBarcode from "jsbarcode";
import { Toast } from "vant";

export default {
  data() {
    return {
      showDialog: false,
      order: {},
      status: ["", "预约", "预约确认", "取消", "爽约"],
    };
  },
  created() {
    this.order = JSON.parse(window.sessionStorage.getItem("order-detail"));
  },
  methods: {
    submitData() {
      //打开弹窗
      this.showDialog = true;
    },
    submit() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        orderId: this.order.OrderId,
        patientId: this.order.PatientId,
      };
      this.$http
        .post("/api/appointment/cancelRegister", postData)
        .then((res) => {
          console.log(res.data);
          this.$router.push("/register");
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
      //关闭弹窗
      this.showDialog = false;
    },
    barCode() {
      JsBarcode("#canvas", this.order.OrderId, {
        lineColor: "#000", //线条颜色
        background: "transparent",
        width: 4, //线宽
        height: 40, //条码高度
        displayValue: true, //是否显示文字信息
      });
    },
  },
  mounted() {
    this.barCode();
  },
};
</script>
<style scoped>
@import url(//at.alicdn.com/t/font_2783444_lvw52vhf2xd.css);
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.body {
  background-color: #f8f9f9;
}
.detail {
  width: 100%;
  height: 100%;
}
.detail1 {
  height: 130px;
  font-size: 14px;
  overflow: hidden;
}
.detail2 {
  height: 100px;
  line-height: 20px;
  margin: auto;
  margin-top: 20px;
}
.detail1 canvas {
  width: 7rem;
  height: 80px;
}
.detail4 {
  width: 9rem;
  height: 300px;
  background-color: #ffff;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 30px;
}
.detail4 span {
  padding-left: 5px;
  padding-right: 5px;
}
.detail5 {
  height: 150px;

  font-size: 0.35rem;
  border-bottom: 1px solid #e4e4e4;
}
.detail7 {
  height: 150px;
  font-size: 0.35rem;
}
.detail6 {
  height: 35px;
  line-height: 35px;
  display: flex;
  justify-content: space-between;
}
.detail8 {
  height: 46px;
  line-height: 46px;
  display: flex;
  justify-content: space-between;
}
.detail9 {
  height: 46px;
  border-bottom: 1px solid #e4e4e4;
  line-height: 46px;
  display: flex;
  justify-content: space-between;
}
.detail20 {
  width: 9rem;
  height: 50px;
  border-radius: 10px;
  margin: auto;
  color: #a9aaaa;
  border: 1px solid #45e4ae;
}

.van-dialog {
  height: 400px;
  /* background-color: red; */
  background-color: rgba(0, 0, 0, 0);
}
.a1 {
  height: 200px;
  background-color: #fff;
  margin-top: 60px;
  border-radius: 20px;
  margin-bottom: 50px;
}
.a2 {
  width: 80px;
  height: 80px;
  margin: auto;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: -68%;
  right: 0;
}
.a21 {
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  background-color: #dbf5ec;
  margin: auto;
  margin-top: 6px;
}
.a31 {
  height: 50px;
}
.a31 img {
  width: 50px;
  height: 50px;
}

.dialog-message {
  font-size: 0.45rem;
  padding-top: 40px;
}
.dialog-message1 {
  font-size: 0.45rem;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
</style>
